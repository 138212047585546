.carousel.carousel-slider {
  overflow: visible !important;
}

.change-language-override-paper {
  left: 0 !important;
}

/* 
  Removes annoying recaptcha logo. This is permitted by Google as long as we link privacy policy and TOS
  In the user flow which we do on login and signup
*/
.grecaptcha-badge {
  visibility: hidden;
}

.twitter-tweet {
  width: 350px !important;
}
